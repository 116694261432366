<template>
  <div class="page">
    <b-card>
      <vue-good-table
        ref="recordTable"
        :columns="fields"
        :rows="getRecordList"
        :group-options="{
          enabled: true,
          collapsable: true
        }"
        :sort-options="{
          enabled: false,
        }"
        style-class="vgt-table condensed"
        :row-style-class="getRowClass"
      >
        <!-- header -->
        <template
          slot="table-header-row"
          slot-scope="props"
          class="dddddd"
        >
          <span
            v-if="props.column.field == 'id'"
            class="idContainer"
            :class="props.row.haveChildren ? 'haveChildren' : 'noChildren'"
          >
            <span @click="onIDClick(props.row.id, props.row.haveChildren)">{{ props.row.id }}</span>
          </span>
          <span
            v-else-if="props.column.field == 'status'"
            class="d-block w-100"
          >
            <span class="recordStatus d-block w-100"><b-badge
              class="w-100 text-center"
              :variant="getVariant(props.row.status)"
            >{{ props.row.status }}</b-badge></span>
          </span>
          <span
            v-else-if="props.column.field == 'package_type'"
          >
            <span class="text-capitalize">{{ props.row.package_type }}</span>
          </span>
          <span
            v-else-if="props.column.field == 'updated_at'"
          >
            {{ new Date(props.row.updated_at).toLocaleString('en-CA') }}
          </span>
          <span
            v-else-if="props.column.field == 'message'"
          >
            <b-button
              v-if="props.row.message"
              variant="outline-primary"
              class="d-block m-auto"
              @click="onMsgShowClick(props.row.message)"
            >Show</b-button>

            <div
              v-else
              class="text-center"
            >
              <feather-icon
                icon="MinusIcon"
                size="12"
              />
            </div>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span
            v-if="props.column.field == 'status'"
          >
            <span class="recordStatus">{{ props.row.status }}</span>
          </span>
          <span
            v-else-if="props.column.field == 'updated_at'"
          >
            {{ new Date(props.row.updated_at).toLocaleString('en-CA') }}
          </span>
        </template>
      </vue-good-table>

      <div class="d-flex justify-content-center mt-2">
        <b-pagination
          v-model="currentPage"
          :total-rows="getRows"
          :per-page="perPage"
          aria-controls="my-table"
          @change="onPageChange"
        />
      </div>
    </b-card>

    <b-modal
      id="record-message-modal"
      v-model="isShowModal"
      title="Record Message"
      ok-only
      ok-variant="outline-primary"
      centered
      @ok="handleOk"
    >
      <p>{{ message }}</p>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BCard,
  BPagination,
  BBadge,
  // BTable,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  components: {
    BButton,
    BCard,
    BPagination,
    BModal,
    BBadge,
    VueGoodTable,
    // BTable,
  },
  data() {
    return {
      recordList: [],
      // fields: ['id', 'package_type', 'class', 'status', 'listing_added', 'listing_updated', 'listing_total', 'updated_at'],
      fields: [
        { label: 'ID', field: 'id' },
        { label: 'Status', field: 'status' },
        { label: 'Class', field: 'class' },
        { label: 'Listing Added', field: 'listing_added' },
        { label: 'Listing Updated', field: 'listing_updated' },
        { label: 'Listing Total', field: 'listing_total' },
        { label: 'Package Type', field: 'package_type' },
        { label: 'Updated At', field: 'updated_at' },
        { label: 'Message', field: 'message' },
      ],
      currentPage: 1,
      perPage: 25,
      recordTotalCount: 1,
      selectedPage: [1],
      message: '',
      isShowModal: false,
    }
  },
  computed: {
    getRecordList() {
      // const temp = this.recordList.slice()
      // return temp.slice((this.currentPage - 1) * 25, this.currentPage * 25)
      return this.recordList
    },
    getRows() {
      return Math.ceil(this.recordTotalCount / 25)
    },
  },
  created() {
    this.loadRecords()
  },
  methods: {
    loadRecords() {
      this.$store.dispatch('superadmin/getRecords', { page: 1 }).then(response => {
        this.recordTotalCount = response.data.total

        this.recordList = response.data.data.map(element => {
          return {
            id: element.id,
            status: element.status,
            class: element.class,
            listing_added: element.listing_added ? element.listing_added : 0,
            listing_updated: element.listing_updated ? element.listing_updated : 0,
            listing_total: element.listing_total ? element.listing_total : 0,
            package_type: element.package_type,
            updated_at: element.updated_at,
            isParentPackage: true,
            message: element.message,
            children: [],
            haveChildren: !(!element.listing_total || element.class === 'Commercial Photo Full Cycle'),
          }
        })
      }).catch(err => {
        console.error(err)
      })
    },
    onPageChange(p) {
      this.$refs.recordTable.collapseAll()
      this.$store.dispatch('superadmin/getRecords', { page: p }).then(response => {
        if (response.code === 200) {
          this.recordList = []
          response.data.data.forEach(element => {
            this.recordList.push({
              id: element.id,
              status: element.status,
              class: element.class,
              listing_added: element.listing_added ? element.listing_added : 0,
              listing_updated: element.listing_updated ? element.listing_updated : 0,
              listing_total: element.listing_total ? element.listing_total : 0,
              package_type: element.package_type,
              updated_at: element.updated_at,
              isParentPackage: true,
              message: element.message,
              children: [],
              haveChildren: !(!element.listing_total || element.class === 'Commercial Photo Full Cycle'),
            })
          })

          this.selectedPage.push(p)
        }
      }).catch(err => {
        console.error(err)
      })
    },
    onIDClick(id, isExist) {
      // this.$refs.recordTable.collapseAll()
      if (isExist) {
        const target = this.getRecordList.findIndex(s => {
          return s.id === id
        })

        if (this.getRecordList[target].children.length === 0) {
          this.$store.dispatch('superadmin/getSubRecords', id).then(response => {
            if (response.code === 200) {
              this.getRecordList[target].children = response.data.map(element => {
                return {
                  id: element.id,
                  status: element.status,
                  class: element.class,
                  listing_added: element.listing_added ? element.listing_added : 0,
                  listing_updated: element.listing_updated ? element.listing_updated : 0,
                  listing_total: element.listing_total ? element.listing_total : 0,
                  package_type: element.package_type,
                  updated_at: element.updated_at,
                  isParentPackage: false,
                }
              })
            }
          }).catch(err => {
            console.error(err)
          })
        }
      }
    },
    getRowClass(row) {
      if (!row.isParentPackage) {
        return 'subRecord'
      }

      if (!row.haveChildren) {
        return 'noChildren'
      }

      return 'haveChildren'
    },
    getVariant(status) {
      switch (status) {
        case 'completed': return 'success'
        case 'error': return 'danger'
        default: return 'dark'
      }
    },
    onMsgShowClick(msg) {
      this.message = msg
      this.isShowModal = true
    },
    handleOk() {
      this.isShowModal = false
      this.message = ''
    },
  },
}
</script>

<style>
    .b-table-details > td {
        padding: 0 !important;
    }

    .hidden_header {
        opacity: 0;
    }

    .noChildren .triangle {
        display: none;
    }

    .noChildren .idContainer {
        margin-left: 16px;
    }

    .haveChildren .idContainer {
        cursor: pointer;
    }

    .subRecord td:first-child {
      text-align: right !important;
    }

    .recordStatus {
      text-transform: capitalize;
    }
</style>
